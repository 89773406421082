import {
  Add,
  Clear,
  Collections,
  Delete,
  Edit,
  Search
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { QuestionApiService } from "app/services/Ananse/QuestionApiService";
import { ColletionType, FileType, QuestionType, QuizType } from "app/types";

import FrameModal from "../component/FrameModal";
import PreViewQuiz from "../component/preViewQuiz";
import { selectInput } from "../mock";
import ArrangeSequenceModal from "./ArrangeSequenceModal";
import { useTranslation } from "_metronic/i18n/language";

interface QuestionsTableProps {
  collections: ColletionType[];
  questions: QuestionType[];
  quiz: QuizType;
  onAdd: () => void;
  onDelete: (questionId: number) => void;
  onEdit: (question: QuestionType) => void;
  onRefresh: () => void;
}

const questionService = new QuestionApiService();

const QuestionsTable: React.FC<QuestionsTableProps> = ({
  collections,
  questions,
  quiz,
  onAdd,
  onDelete,
  onEdit,
  onRefresh
}) => {
  const translate = useTranslation();
  const [filteredQuestions, setFilteredQuestions] = useState<QuestionType[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof QuestionType>(
    "arrangeQuestionSequence"
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalImages, setModalImages] = useState<FileType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");

  useEffect(() => {
    if (!searchTerm) {
      setFilteredQuestions(questions);
      return;
    }

    const lowerCasedSearchTerm = searchTerm.toLowerCase();

    const filtered = questions.filter(({ rightAnswer, quest }) => {
      const normalizedRightAnswer = rightAnswer
        ? rightAnswer.toLowerCase()
        : "";
      const normalizedQuest = quest ? quest.toLowerCase() : "";

      return (
        normalizedRightAnswer.includes(lowerCasedSearchTerm) ||
        normalizedQuest.includes(lowerCasedSearchTerm)
      );
    });

    setFilteredQuestions(filtered);
  }, [searchTerm, questions]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (property: keyof QuestionType) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedQuestions = filteredQuestions.sort((a, b) => {
    const aValue = orderBy === "quest" ? a.question[orderBy] : a[orderBy];
    const bValue = orderBy === "quest" ? b.question[orderBy] : b[orderBy];

    if (aValue === undefined) return 1;
    if (bValue === undefined) return -1;

    if (order === "asc") {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const getFragrance = (fragranceId: any) => {
    if (!collections.length) {
      return fragranceId;
    }
    const fragrance = collections.find(
      collection => collection.fragranceId == fragranceId
    );
    return fragrance?.fragrance.name || "Nenhum";
  };

  const getQuestionType = (value: string) => {
    const findType = selectInput.find(v => v.type === value);
    return findType?.name || "";
  };

  const handleMouseEnter = async (question: QuestionType) => {
    setLoading(true);
    setModalTitle(question.question.quest);
    setOpenModal(true);
    const images: FileType[] = [];

    for (const answer of question.answers) {
      const response = await questionService.getFileByFileOwnerId(
        answer.quizQuestionAnswerId.toString()
      );
      const imageData = response[0];
      if (imageData && imageData.tempUri) {
        images.push(imageData);
      }
    }

    setModalImages(images);
    setLoading(false);
  };

  const handleMouseLeave = () => {
    setOpenModal(false);
    setModalImages([]);
    setModalTitle("");
  };

  return (
    <Paper>
      <Stack
        sx={{
          padding: 1,
          flexDirection: "row",
          justifyContent: "space-evenly"
        }}
      >
        <FrameModal>
          <PreViewQuiz quiz={quiz} />
        </FrameModal>
        <ArrangeSequenceModal onSave={onRefresh} questions={sortedQuestions} />
        <IconButton title={translate("screens_quiz_labels_addQuestion")} onClick={onAdd}>
          <Add />
        </IconButton>
        <TextField
          label={translate("labels_search")}
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          fullWidth
          size="small"
          sx={{ marginLeft: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearchTerm("")}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translate("screens_quiz_list_actions")}</TableCell>
              <TableCell
                sortDirection={
                  orderBy === "arrangeQuestionSequence" ? order : false
                }
              >
                <TableSortLabel
                  active={orderBy === "arrangeQuestionSequence"}
                  direction={
                    orderBy === "arrangeQuestionSequence" ? order : "asc"
                  }
                  onClick={() => handleSortRequest("arrangeQuestionSequence")}
                >
                  {translate("labels_sequence")}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === "quest" ? order : false}>
                <TableSortLabel
                  active={orderBy === "quest"}
                  direction={orderBy === "quest" ? order : "asc"}
                  onClick={() => handleSortRequest("quest")}
                >
                  {translate("screens_quiz_list_questions")}
                </TableSortLabel>
              </TableCell>
              <TableCell>{translate("labels_answers")}</TableCell>
              <TableCell
                sortDirection={orderBy === "rightAnswer" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "rightAnswer"}
                  direction={orderBy === "rightAnswer" ? order : "asc"}
                  onClick={() => handleSortRequest("rightAnswer")}
                >
                  {translate("screenApp_results_rightResponse")}
                </TableSortLabel>
              </TableCell>

              <TableCell
                sortDirection={orderBy === "fragranceId" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "fragranceId"}
                  direction={orderBy === "fragranceId" ? order : "asc"}
                  onClick={() => handleSortRequest("fragranceId")}
                >
                  {translate("screens_collectionDevice_table_fragrance")}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === "type" ? order : false}>
                <TableSortLabel
                  active={orderBy === "type"}
                  direction={orderBy === "type" ? order : "asc"}
                  onClick={() => handleSortRequest("type")}
                >
                  {translate("screenApp_quiz_questionType")}
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === "createdAt" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "createdAt"}
                  direction={orderBy === "createdAt" ? order : "asc"}
                  onClick={() => handleSortRequest("createdAt")}
                >
                  {translate("screens_quiz_list_created")}
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === "updatedAt" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "updatedAt"}
                  direction={orderBy === "updatedAt" ? order : "asc"}
                  onClick={() => handleSortRequest("updatedAt")}
                >
                  {translate("screens_quiz_list_updated")}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedQuestions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(question => (
                <TableRow key={question.questionId}>
                  <TableCell>
                    <IconButton title={translate("buttons_edit")} onClick={() => onEdit(question)}>
                      <Edit />
                    </IconButton>
                    <IconButton
                      title={translate("deleteRegister_delete")}
                      onClick={() => onDelete(question.quizQuestionId)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {question.arrangeQuestionSequence || "-"}
                  </TableCell>
                  <TableCell>{question.question.quest || "-"}</TableCell>
                  <TableCell>
                    {question.answers.map(answer => (
                      <Typography key={answer.answerId}>
                        {answer.answer?.label}
                      </Typography>
                    ))}
                    {question.type === "image" && (
                      <IconButton
                        title="Visualizar imagens"
                        onClick={() => handleMouseEnter(question)}
                      >
                        <Collections />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>{question.rightAnswer}</TableCell>
                  <TableCell>{getFragrance(question.fragranceId)}</TableCell>
                  <TableCell>{getQuestionType(question.type)}</TableCell>
                  <TableCell>
                    {question.createdAt
                      ? new Date(question?.createdAt).toLocaleDateString(
                          "pt-BR",
                          {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric"
                          }
                        )
                      : ""}
                  </TableCell>
                  <TableCell>
                    {question.updatedAt
                      ? new Date(question.updatedAt).toLocaleDateString(
                          "pt-BR",
                          {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric"
                          }
                        )
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredQuestions.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={openModal} onClose={handleMouseLeave}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 150,
                flexDirection: "column"
              }}
            >
              <CircularProgress color="success" />
              <Typography variant="caption" display="block">
                {translate("defaultMessages_loading")}
              </Typography>
            </Box>
          ) : (
            <ImageList
              sx={{ width: 500, height: 500 }}
              cols={2}
              rowHeight={184}
            >
              {modalImages.map((image, index) => (
                <Stack key={index}>
                  <ImageListItem key={index}>
                    <img
                      src={image.tempUri}
                      alt={image.name || `Imagem ${index + 1}`}
                    />
                  </ImageListItem>
                  <Typography variant="caption" display="block">
                    {image.name}
                  </Typography>
                </Stack>
              ))}
            </ImageList>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMouseLeave} color="error" variant="contained">
            {translate("buttons_close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default QuestionsTable;
